<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div>
        <h2 class="text-base font-semibold uppercase tracking-wide" :class="textbrand600">Everything you need to know</h2>
        <p class="mt-2 text-3xl font-extrabold text-gray-900">New Tint Aftercare</p>
        <p class="mt-4 text-lg text-gray-500">Just got a new tint job? Here's what to expect...</p>
      </div>
      <div class="mt-12 lg:mt-0 lg:col-span-2">
        <dl class="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-3 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
          <div v-for="feature in features" :key="feature.name" class="relative">
            <dt>
              <p class="ml-9 text-lg leading-6 font-medium text-gray-900">{{ feature.name }}</p>
            </dt>
            <dd class="mt-2 ml-9 text-base text-gray-500">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
<div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="text-center">
        <p class="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Feeling generous?</p>
        <p class="max-w-xl mt-5 mx-auto text-xl text-gray-500">If you like your new tint job and want to share your experience, hit us up on Google, like these fine folks!</p>
      </div>
    </div>
     <div class="bg-black p-5">
      <div :class="reviews"></div>
  </div>
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import configSetup from '@/setup.js'



export default {
    created() {
      const {company} = configSetup()
      document.title = `${company} | After Care`
},

  setup() {
    const {company, textbrand600, reviews} = configSetup()
    const features = [
  {
    name: 'Do NOT roll down your windows!',
    description: 'No drive-thru for you... give it a minimum of 3 days in warm weather and 7 - 14 days in cold weather. Your new tint is applied using a soapy water solution and needs time to dry. If you look closely and at an angle, you\'ll see a haze - that needs time to disperse. When your windows are safe to roll down, that haze will be gone. If you roll them down before it\'s dry, the bottom could peel up. We don\'t want that...',
  },
  { name: 'Do NOT clean the tint!', description: 'You can clean the outside of your vehicle but please don\'t try to clean your new window film until after the drying process is done. The micro movements will cause friction, which in turn can cause bubbles. We don\'t want that either. After the drying process, you can clean, but do not use any product with amonia, like Windex, this stuff will degrade the dye. Use soapy water and a microfiber towl.' },
  {
    name: 'Are you noticing some bubbles? Hold up...',
    description: 'When it comes to bubbles, there\'s two types. Water bubbles and air bubbles. Water bubbles are perfectly normal and will go away after the drying process. They look clear from the outside AND clear from the inside. Air bubbles, now these are the ones we don\'t want. They are "white" from the outside BUT clear from the inside. Sometimes we can get lucky and push these down after the drying process. And sometimes the film needs to be completely removed to re-apply a new one. If this happens, get a hold of us ASAP so we can bring your car in for a redo.',
  },
  { name: 'Do you see little white dots?', description: `This often happens, at ${company}, we try our best to avoid them but it's pretty difficult. These little white dots are caused by contamination like dust, debris, lint and such, that gets in between the film and the glass. After the drying process, most of these can get pushed down and will be barely noticesable but won\'t be completely gone. Also, you'll notice the white dots are tiny bulges when observed from the inside.` },
  { name: 'There\'s probably a white halo around your rear windshield', description: 'Another perfectly normal phenomenon! The vast majority of cars have a ceramic type material called a "dot matrix" lining the rear windshield. This material is raised off the glass and therefor the film cannot lay flat on the glass allowing air to get trapped underneath. Depending on your vehicle, you could also see this happen on your rear quarter windows.' },
  { name: 'Finally, let\'s talk Warranty', description: 'You got lifetime warranty! This covers both the film and the installation. So... if you have any problems like bubbles, peeling or fading. We got you! Just reach out and we\'ll get you back in to fix it, for as long as you still have the same vehicle we tinted.' },
]
        onMounted(() => {
            const googleReviews = document.createElement('script')
            googleReviews.setAttribute('src', 'https://apps.elfsight.com/p/platform.js')
            document.head.appendChild(googleReviews)
        })
    return {
      features, textbrand600, reviews
    }
  },
}
</script>